(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/reveal-animation/assets/javascripts/reveal-animation.js') >= 0) return;  svs.modules.push('/components/components/reveal-animation/assets/javascripts/reveal-animation.js');

var svs = svs || {};


(function(svs) {
  'use strict';

  var log = svs.core.log.getLogger('component:reveal-animation');

  svs.components = svs.components || {};

  svs.components.RevealAnimation = function() {
    var $body = $('body');
    var coordinates = null;
    var scrollingPos = null;
    var customOffsetHeight = null;
    var customTargetMargin = 12;
    var useAutoScroll = true;
    var $el = $body.find('.js-reveal-animation');
    if ($el.length === 0) {
      $el = $('<div class="js-reveal-animation reveal-animation"></div>');
      $body.append($el);
      log.debug('Appending div to be animated');
    }

    return {
      setRevealElement: function(el) {
        if (el) {
          $el = $(el);
        }
      },
      runRevealAnimation: function(e, callback, context) {
        var $topContent = $('.js-fixed-top-content');
        var topContentHeight = 0;
        var topContentOffsetTop = 0;
        var topContentOuterHeight = 0;
        if ($topContent.length > 0) {
          topContentHeight = $topContent.height();
          topContentOffsetTop = $topContent.offset().top;
          topContentOuterHeight = $topContent.outerHeight();
        }
        var menuWidth = 0;
        var headerHeight = customTargetMargin + topContentHeight + topContentOffsetTop;
        if (customOffsetHeight) {
          headerHeight = headerHeight + customOffsetHeight;
        }

        var xaxel =
            e.clientX -
            (menuWidth + parseInt($('.main-content').css('margin-left'), 10));
        var yaxel = e.clientY - headerHeight + window.scrollY;
        coordinates = { x: xaxel, y: yaxel };
        scrollingPos = { x: window.scrollX, y: window.scrollY };

        $el.css(
          'top',
          topContentOuterHeight +
          topContentOffsetTop -
            window.scrollY + customTargetMargin
        );

        $el.on(
          'webkitTransitionEnd otransitionend msTransitionEnd transitionend',
          function() {
            $el.off('webkitTransitionEnd otransitionend msTransitionEnd transitionend');
            $el.removeClass('reveal-animation-show');
            if (useAutoScroll) {
              $body.css({ overflowY: 'auto' });
              window.scroll(0, 0);
            }
            if (callback) {
              callback.call(context);
            }
          }
        );
        useAutoScroll && $body.css({ overflowY: 'hidden' });
        $el.addClass('reveal-animation-show').css({
          OTransformOrigin: xaxel + 'px ' + yaxel + 'px',
          msTransformOrigin: xaxel + 'px ' + yaxel + 'px',
          mozTransformOrigin: xaxel + 'px ' + yaxel + 'px',
          webkitTransformOrigin: xaxel + 'px ' + yaxel + 'px',
          transformOrigin: xaxel + 'px ' + yaxel + 'px'
        });
      },
      prepareRevertRevealAnimation: function(callback, context) {
        $el.on(
          'webkitTransitionEnd otransitionend msTransitionEnd transitionend',
          function() {
            $el.off('webkitTransitionEnd otransitionend msTransitionEnd transitionend');
            if (callback) {
              callback.call(context);
            }
          }
        );
        $el.addClass('reveal-animation-revert reveal-prepare').css({
          OTransformOrigin: coordinates.x + 'px ' + coordinates.y + 'px',
          msTransformOrigin: coordinates.x + 'px ' + coordinates.y + 'px',
          mozTransformOrigin: coordinates.x + 'px ' + coordinates.y + 'px',
          webkitTransformOrigin: coordinates.x + 'px ' + coordinates.y + 'px',
          transformOrigin: coordinates.x + 'px ' + coordinates.y + 'px'
        });
      },
      revertRevealAnimation: function(callback, context) {
        $el.on(
          'webkitTransitionEnd otransitionend msTransitionEnd transitionend',
          function() {
            $el.off('webkitTransitionEnd otransitionend msTransitionEnd transitionend');
            $el.removeClass('reveal-prepare');
            if (callback) {
              callback.call(context);
            }
          }
        );
        if (useAutoScroll) {
          $body.css({ overflowY: 'auto' });
          window.scroll(scrollingPos.x, scrollingPos.y);
        }
        $el.removeClass('reveal-animation-revert');
        coordinates = null;
      },
      isRevertable: function() {
        return coordinates !== null;
      },
      setUseAutoScroll: function(use) {
        useAutoScroll = use;
      },

      setCustomOffsetHeight: function(offsetHeight) {
        customOffsetHeight = offsetHeight;
      },

      setCustomTargetMargin: function(targetMargin) {
        customTargetMargin = targetMargin;
      }
    };
  };

  svs.components.revealAnimation = new svs.components.RevealAnimation($('.main-content'));
})(svs);


 })(window);